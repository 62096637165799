import Vue from "vue";
import XEUtils from "xe-utils";

import {
  // 核心
  VXETable,
  Modal,
} from "vxe-table";

import VXETablePluginElement from "vxe-table-plugin-element";
import "vxe-table-plugin-element/dist/style.css";
import "vxe-table/styles/index.scss";
import zhCN from "vxe-table/lib/locale/lang/zh-CN";

VXETable.use(VXETablePluginElement);

// 全局默认参数
VXETable.setup({
    size: "medium",
    // version: 0,
    zIndex: 1002,
    table: {
      highlightHoverRow: true,
      showHeaderOverflow: true,
      sortConfig: {
        trigger: "cell",
        remote: true,
      },
      filterConfig: {
        remote: true,
      },
      checkboxConfig: {
        highlight: true,
        range: true,
      },
    },
    grid: {
      toolbarConfig: {
        refresh: true,
        zoom: true,
        custom: true,
      },
      proxyConfig: {
        seq: true,
        sort: true,
        filter: true,
        form: true,
        props: {
          result: "records",
          total: "total",
        },
      },
      pagerConfig: {
        pageSize: 20,
        pageSizes: [5, 10, 15, 20, 50, 100],
        autoHidden: true,
      },
    },
    input: {
      clearable: true,
    },
    modal: {
      transfer: true,
    },
    i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args),
  });

Vue.use(Modal);