var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.member)?_c('div',{staticClass:"shrink-0 grow-0"},[(_vm.member.data.attributes.avatar)?_c('img',{staticClass:"object-cover mx-auto",class:[
                _vm.borderRadius,
                {
                    'border-3 border-white dark:border-dark-background': _vm.isBorder,
                },
            ],style:({ width: _vm.size + 'px', height: _vm.size + 'px' }),attrs:{"src":_vm.avatar}}):_c('div',{staticClass:"flex items-center justify-center mx-auto",class:[
                _vm.borderRadius,
                {
                    'border-3 border-white dark:border-dark-background': _vm.isBorder,
                    'dark:bg-4x-dark-foreground bg-light-background': !_vm.member.data.attributes.color,
                },
            ],style:({
                width: _vm.size + 'px',
                height: _vm.size + 'px',
                background: _vm.member.data.attributes.color ? _vm.member.data.attributes.color : '',
            })},[_c('span',{staticClass:"font-extrabold uppercase text-white dark:text-gray-900",class:[_vm.fontSize, {'!text-gray-900': !_vm.member.data.attributes.color}]},[_vm._v(" "+_vm._s(_vm.letter)+" ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }