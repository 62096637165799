const routesSite = [
    {
        name: 'Site',
        path: '/site',
        component: () => import(/* webpackChunkName: "chunks/platform" */ '../views/Platform'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'SiteSetting',
                path: '/site/site',
                component: () => import(/* webpackChunkName: "chunks/siteSetting" */ '../views/Site/Site'),
                meta: {
                    requiresAuth: true,
                    title: '站点设置'
                },
            },
            {
                name: 'SiteStorage',
                path: '/site/storage',
                component: () => import(/* webpackChunkName: "chunks/siteStorage" */ '../views/Site/Storage'),
                meta: {
                    requiresAuth: true,
                    title: '图库信息'
                },
            }
        ],
    },
]

export default routesSite
