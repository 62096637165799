// const routesTeam = [
//     {
//         name: 'Invitation',
//         path: '/team-folder-invitation/:id',
//         component: () => import(/* webpackChunkName: "chunks/invitation" */ '../views/Teams/Invitation'),
//         meta: {
//             requiresAuth: false,
//         },
//     },
// ]

const routesTeam = [
    {
        name: 'Team',
        path: '/team',
        component: () => import(/* webpackChunkName: "chunks/Platform" */ '../views/Platform'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'UserManage',
                path: '/team/user',
                component: () => import(/* webpackChunkName: "chunks/profile" */ '../views/Teams/User'),
                meta: {
                    requiresAuth: true,
                    title: '账号管理',
                },
            },
            {
                name: 'RoleManage',
                path: '/team/role',
                component: () => import(/* webpackChunkName: "chunks/role" */ '../views/Teams/Role'),
                meta: {
                    requiresAuth: true,
                    title: '角色管理',
                },
            },
            {
                name: 'MenuManage',
                path: '/team/menu',
                component: () => import(/* webpackChunkName: "chunks/menu" */ '../views/Teams/Menu'),
                meta: {
                    requiresAuth: true,
                    title: '菜单管理',
                },
            },
            {
                name: 'TeamManage',
                path: '/team/team',
                component: () => import(/* webpackChunkName: "chunks/role" */ '../views/Teams/Team'),
                meta: {
                    requiresAuth: true,
                    title: '团队管理',
                },
            }
        ]
    }
]

export default routesTeam
