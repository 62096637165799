<template>
    <div class="flex px-2.5 md:px-6">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'AuthContentWrapper',
}
</script>
