import i18n from '../i18n'
import store from '../store/index'
import { events } from '../bus'
import {isArray} from "lodash";
import { Message, MessageBox } from 'element-ui';
import DayJS from 'dayjs'
import Api from '@/api';
import { Base64 } from 'js-base64'
import router from '@/router';

const itemHelpers = {
    install(Vue) {
        Vue.prototype.$emptyTrash = function () {
            store.dispatch('emptyTrash')
        }
        Vue.prototype.$emptyTrashQuietly = function () {
            store.dispatch('emptyTrashQuietly')
        }

        Vue.prototype.$shareCancel = function () {
            store.dispatch('shareCancel')
        }

        Vue.prototype.$toggleFavourites = function (entry) {
            // Check if folder is in favourites and then add/remove from favourites

            if (typeof entry.from !== 'undefined') {
                let fromFileIsStar = entry.from.isStar;
                
                if (fromFileIsStar) {
                    this.$store.dispatch('removeFromFavourites', entry)
                } else {
                    this.$store.dispatch('addToFavourites', entry)
                }

                let clipboard = store.getters.clipboard;
                for (let i = 0; i < clipboard.length; i++) {
                    clipboard[i].isStar = fromFileIsStar === 1 ? 0 : 1;
                }
                
                return ;
            }

            if (!entry.isStar) {
                // Add to favourite folder that is not selected
                entry.isStar = 1;
                this.$store.dispatch('addToFavourites', entry)
                // if (!store.getters.clipboard.includes(entry)) {
                //     this.$store.dispatch('addToFavourites', entry)
                // }

                // // Add to favourites all selected folders
                // if (store.getters.clipboard.includes(entry)) {
                //     this.$store.dispatch('addToFavourites', null)
                // }
            } else {
                entry.isStar = 0;
                this.$store.dispatch('removeFromFavourites', entry)
            }
        }

        Vue.prototype.$renameFileOrFolder = function (entry) {
            events.$emit('popup:open', { name: 'rename-item', item: entry })
        }

        Vue.prototype.$moveFileOrFolder = function (entry) {

            let item = isArray(entry) ? [...entry] : [entry]

            events.$emit('popup:open', { name: 'move', item: item })
        }

        Vue.prototype.$storeFileOrFolder = function (entry, type) {

            let item = isArray(entry) ? [...entry] : [entry]

            events.$emit('popup:open', { name: 'store', item: item, type })
        }

        Vue.prototype.$createFolderByPopup = function () {
            // Show alert message when create folder is disabled
            if (store.getters.user && !store.getters.user.data.meta.restrictions.canCreateFolder) {
                Vue.prototype.$temporarilyDisabledFolderCreate()

                return
            }

            events.$emit('popup:open', { name: 'create-folder' })
        }

        Vue.prototype.$createFolder = function () {
            // Show alert message when create folder is disabled
            // if (store.getters.user && !store.getters.user.data.meta.restrictions.canCreateFolder) {
            //     Vue.prototype.$temporarilyDisabledFolderCreate()

            //     return
            // }

            store.dispatch('createFolder', {
                name: `新建文件夹_${DayJS().format('YYYYMMDDHHmmss')}`,
            })
        }

        Vue.prototype.$downloadSelection = function (item = undefined) {
            // Show alert message when download is disabled
            // if (store.getters.user && !store.getters.user.data.meta.restrictions.canDownload) {
            //     Vue.prototype.$temporarilyDisabledDownload()

            //     return
            // }

            // Download folder zip
            if (item && item.type === 0) {
                store.dispatch('downloadZip', item)

                return
            }

            // Download single item
            if (item && item.fileUrl && item.type === 1) {
                // 下载图片质量提示
                MessageBox.confirm('请选择下载图片质量', '下载文件', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '下载原图',
                    cancelButtonText: '下载高清图'
                  })
                    .then(() => {
                        // share
                        if (router.currentRoute.name === 'Public' || router.currentRoute.name === 'PublicFile') {
                            Api.share.updateShareNum({ id: router.currentRoute.params.shareId });
                        }

                        Vue.prototype.$downloadFile(
                            item.downloadLink,
                            item.name + '.' + item.suffix
                        )
                    })
                    .catch(action => {
                        if (action === 'cancel') {
                            Vue.prototype.$downloadFile(
                                item.thumbnailDownloadLink,
                                item.name + '.' + item.suffix
                            )
                        }
                    });

                return
            } else if (item) {
                Vue.prototype.$downloadFile(
                    item.downloadLink,
                    item.name + '.' + item.suffix
                )
            }

            // Download selection
            let clipboard = store.getters.clipboard

            if (clipboard.length > 1 || (clipboard.length === 1 && clipboard[0].type === 0)) {
                store.dispatch('downloadZip')
            }
        }

        Vue.prototype.$dissolveTeamFolder = function (folder) {
            events.$emit('confirm:open', {
                title: i18n.t('really_dissolve_team'),
                message: i18n.t(
                    'really_dissolve_team_desc'
                ),
                action: {
                    id: folder.data.id,
                    operation: 'dissolve-team-folder',
                },
            })
        }

        Vue.prototype.$detachMeFromTeamFolder = function (folder) {
            events.$emit('confirm:open', {
                title: i18n.t('really_leave_team'),
                message: i18n.t(
                    "really_leave_team_desc"
                ),
                action: {
                    id: folder.data.id,
                    operation: 'leave-team-folder',
                },
            })
        }

        Vue.prototype.$createTeamFolder = function () {
            // Show alert message when create folder is disabled
            if (!store.getters.user.data.meta.restrictions.canCreateTeamFolder) {
                Vue.prototype.$temporarilyDisabledFolderCreate()

                return
            }

            events.$emit('popup:open', { name: 'create-team-folder' })
        }

        Vue.prototype.$convertAsTeamFolder = function (entry) {
            events.$emit('popup:open', {
                name: 'create-team-folder',
                item: entry,
            })
        }

        Vue.prototype.$createFileRequest = function (entry = undefined) {
            events.$emit('popup:open', {
                name: 'create-file-request',
                item: entry,
            })
        }

        Vue.prototype.$updateTeamFolder = function (entry) {
            events.$emit('popup:open', {
                name: 'update-team-folder',
                item: entry,
            })
        }

        Vue.prototype.$removeFavourite = function (folder) {
            store.dispatch('removeFromFavourites', folder)
        }

        Vue.prototype.$deleteFileOrFolder = function (entry = undefined) {
            if (!store.getters.clipboard.includes(entry)) {
                store.dispatch('deleteItem', entry)
            }

            if (store.getters.clipboard.includes(entry)) {
                store.dispatch('deleteItem')
            }
        }

        Vue.prototype.$restoreFileOrFolder = function (entry) {
            if (!store.getters.clipboard.includes(entry)) store.dispatch('restoreItem', entry)

            if (store.getters.clipboard.includes(entry)) store.dispatch('restoreItem', null)
        }

        Vue.prototype.$shareFileOrFolder = function (entry, action = 'create') {
            // let event = action === 'update' ? 'share-edit' : 'share-create'
            let event = 'share-create';

            events.$emit('popup:open', {
                name: event,
                item: entry,
                action
            })
        }

        Vue.prototype.$deleteShareFileOrFolder = async function(entry) {
            await MessageBox.confirm(`取消后，分享将被删除，且无法被查看，是否继续？`, '取消分享', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              });
            await Api.share.removeShare({ id: entry.id });

            Message.success('取消成功');

            store.dispatch('getMySharedItems', 1);
        }

        Vue.prototype.$previewFile = function(entry) {
            // 标记打开时间
            const markOpenTime = item => {
                Api.netdisk.markOpenTime({
                    id: item.id,
                }).then(res => {
                    let file = this.$store.getters.entries.find(entry => entry.id === item.id);
                    if (file) {
                        if (!isNaN(res)) {
                            file.openTime = res;
                        }
                    }
                });
            };

            const handleFilePreview = (item) => {
                // kkview file preview
                if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'zip'].indexOf(item.suffix) >= 0) {
                    markOpenTime(item);
                    let base64FileUrl = encodeURIComponent(Base64.encode(item.downloadLink));
                    this.$newWindow(`${this.config.kkFileViewHost}/onlinePreview?url=${base64FileUrl}`);
                    return ;
                }
            };

            if (entry.type === 1 || ['mp4', 'pdf'].indexOf(entry.suffix) >= 0) {
                this.$store.commit('CLIPBOARD_CLEAR')
                this.$store.commit('ADD_ITEM_TO_CLIPBOARD', entry)

                events.$emit('file-preview:show')
                markOpenTime(entry);
            } else if (entry.type !== 0) {
                handleFilePreview(entry);
            }
        }
    },
}

export default itemHelpers
