const routesSuperAdmin = [
    {
        name: 'SuperAdmin',
        path: '/super_admin',
        component: () => import(/* webpackChunkName: "chunks/platform" */ '../views/Platform'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'SuperAdminCompanyList',
                path: '/super_admin/company_list',
                component: () => import(/* webpackChunkName: "chunks/superAdminCompanyList" */ '../views/SuperAdmin/CompanyList'),
                meta: {
                    requiresAuth: true,
                    title: '企业管理'
                },
            },
            {
                name: 'SuperAdminDashboard',
                path: '/super_admin/dashboard',
                component: () => import(/* webpackChunkName: "chunks/superAdminDashboard" */ '../views/SuperAdmin/Dashboard'),
                meta: {
                    requiresAuth: true,
                    title: '系统总览'
                },
            },
            {
                name: 'SuperAdminLog',
                path: '/super_admin/log',
                component: () => import(/* webpackChunkName: "chunks/SuperAdminLog" */ '../views/SuperAdmin/Log'),
                meta: {
                    requiresAuth: true,
                    title: '日志中心'
                },
            }
        ],
    },
]

export default routesSuperAdmin
