require('./bootstrap')
import Vue from 'vue'
import i18n from './i18n'
import VueRouter from 'vue-router'
import router from './router'
import App from './App.vue'
import store from './store'
import { events } from './bus'
import config from './config'

import SubscriptionHelpers from './helpers/SubscriptionHelpers'
import ValidatorHelpers from './helpers/ValidatorHelpers'
import functionHelpers from './helpers/functionHelpers'
import AlertHelpers from './helpers/AlertHelpers'
import itemHelpers from './helpers/itemHelpers'

import "./assets/css/tailwind.css"
import "./assets/sass/app.scss"

Vue.use(VueRouter)
Vue.use(SubscriptionHelpers)
Vue.use(ValidatorHelpers)
Vue.use(functionHelpers)
Vue.use(AlertHelpers)
Vue.use(itemHelpers)

import './element-ui'
import './filters'

import Meta from 'vue-meta'
Vue.use(Meta)

import Directives from './directives'
Vue.use(Directives)

import './vxe'

Vue.config.productionTip = false

// Handle position of Drag & Drop Ghost
document.addEventListener(
    'drag',
    (event) => {
        let multiSelect = document.getElementById('drag-ui')

        multiSelect.style.top = event.clientY + 20 + 'px'
        multiSelect.style.left = event.clientX + 'px'
    },
    false
)

// Handle for drop
document.addEventListener(
    'dragend',
    () => {
        events.$emit('drop')
    },
    false
)

let vueFileManager = new Vue({
    i18n,
    store,
    router,
    data: {
        config,
    },
    render: (h) => h(App),
    metaInfo() {
        // 动态logo
        let linkList = document.querySelectorAll("link");
        let arrLink = [].slice.call(linkList);
        arrLink = arrLink.filter(item => item.rel === 'icon');

        for (let i = 0; i < arrLink.length; i++) {
            arrLink[i].href = store.getters.websiteInfo ? `${store.getters.websiteInfo.icoLink}` : '';
        }

        return {
            title: store.getters.websiteInfo ? `${store.getters.websiteInfo.title}` : '',
        }
    }
}).$mount('#app')
