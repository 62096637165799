import i18n from '../../i18n'
import router from '../../router'
import { events } from '../../bus'
import axios from 'axios'
import Vue from 'vue'
import Api from '../../api'
import { MessageBox, Message } from 'element-ui'
import DayJS from 'dayjs'

const defaultState = {
    processingPopup: undefined,
    isUploadingFolder: false,
    isProcessingFile: false,
    filesInQueueUploaded: 0,
    filesInQueueTotal: 0,
    uploadingProgress: 0,
    fileQueue: [],
}

const actions = {
    downloadZip: ({ getters }, item = undefined) => {
        let files = []
        let fileNames = [];

        // Get if from retrieved item
        if (item && Array.isArray(item)) {
            for (let i = 0; i < item.length; i++) {
                files.push(item[i].id);
                fileNames.push(item[i].name);
            }
        } else if (item) {
            files.push(item.id)
            fileNames.push(item.name);
        }

        // Get ids of selected files
        if (!item) {
            getters.clipboard.forEach((file) => {
                files.push(file.id)
                fileNames.push(file.name);
            })
        }

        Message.info('正在打包，请稍后...');

        let fileName = `${fileNames.length > 1 ? `${fileNames[0]}等${fileNames.length}个文件` : `${fileNames[0]}`}`;
        fileName += `_${DayJS().format('YYYYMMDDHHmmss')}`;
        const requestData = {
            fileName: fileName,
            ids: files
        };

        if (router.currentRoute.name === 'Public') {
            requestData.shareId = router.currentRoute.params.shareId;
        }

        Api.netdisk.batchDownloadFiles(requestData).then(res => {
            // Message.success('打包成功，压缩包正在下载');
            // setTimeout(() => {
            //     Vue.prototype.$downloadFile(res.downLoadUrl);
            // }, 3000);

            let tmr = setInterval(() => {
                (async () => {
                    let result = await Api.netdisk.getBatchDownloadFilesStatus({
                        persistentId: res.persistentId
                    });
                    if (result === true) {
                        clearInterval(tmr);
                        Message.success('打包成功，压缩包正在下载');
                        Vue.prototype.$downloadFile(res.downLoadUrl);
                    }
                })();
            }, 2000);

        });

        // Get route
        // let route = getters.sharedDetail
        //     ? `/api/sharing/zip/${router.currentRoute.params.token}?items=${files.join(',')}`
        //     : `/api/zip?items=${files.join(',')}`

        // Download zip
        // Vue.prototype.$downloadFile(route, 'files.zip')
    },
    moveItem: ({ commit, getters, dispatch }, { to_item, item, type }) => {
        let items = item
            ? [item]
            : getters.clipboard

        let itemsToMove = items.map((data) => {
            return {
                ...data,
                parentId: to_item.data ? to_item.data.id : to_item.id,
                userId: getters.user.id,
            }
        })

        // Remove file preview
        if (!item) {
            commit('CLIPBOARD_CLEAR')
        }

        // Get route
        let route = {
            RequestUpload: `/api/file-request/${router.currentRoute.params.token}/move`,
            Public: `/api/sharing/move/${router.currentRoute.params.token}`,
        }[router.currentRoute.name] || '/api/move'

        let moveToId = to_item.data ? to_item.data.id : to_item.id

        if (router.currentRoute.name === 'Public' || router.currentRoute.name === 'PublicFile') {
            Api.share.storeFile({
                ids: itemsToMove.map(item => item.id),
                parentId: moveToId,
                storeType: type
            }).then(() => {
                if (Vue.prototype.$isThisRoute(router.currentRoute, ['Public', 'PublicFile']))
                    dispatch('getFolderTree')

                Message.success('转存成功，请到系统中查看');
            }).catch((e) => {console.log(e); Vue.prototype.$isSomethingWrong()});
        } else {
            Api.netdisk.moveFile({
                blocId: getters.user.blocId,
                fileStoreId: getters.user.fileStoreId,
                ids: itemsToMove.map(item => item.id).join(),
                parentId: moveToId,
                storeType: router.currentRoute.name === 'Files' ? 1: 0,
                userId: getters.user.id,
            }).then(() => {
                itemsToMove.forEach((item) => {
                    commit('REMOVE_ITEM', item.id)
                    commit('INCREASE_FOLDER_ITEM', moveToId)
    
                    if (item.type === 0)
                        dispatch('getAppData')
    
                    if (Vue.prototype.$isThisRoute(router.currentRoute, ['Public']))
                        dispatch('getFolderTree')
                })
            })
            .catch((e) => {console.log(e); Vue.prototype.$isSomethingWrong()})
        }

        // for (let i = 0; i < itemsToMove.length; i++) {
        //     Api.netdisk.saveFile(itemsToMove[i]).then(() => {
        //         itemsToMove.forEach((item) => {
        //             commit('REMOVE_ITEM', item.id)
        //             commit('INCREASE_FOLDER_ITEM', moveToId)

        //             if (item.type === 0)
        //                 dispatch('getAppData')

        //             if (Vue.prototype.$isThisRoute(router.currentRoute, ['Public']))
        //                 dispatch('getFolderTree')
        //         })
        //     })
        //     .catch((e) => {console.log(e); Vue.prototype.$isSomethingWrong()})
        // }         
    },
    createFolder: ({ commit, getters, dispatch }, folder) => {
        // Get route
        let route = {
            RequestUpload: `/api/file-request/${router.currentRoute.params.token}/create-folder`,
            Public: `/api/sharing/create-folder/${router.currentRoute.params.token}`,
        }[router.currentRoute.name] || '/api/create-folder'

        Api.netdisk.addFolder({
            // "blocId": getters.user.blocId,
            // "fileStoreId": getters.user.fileStoreId,
            // "id": 0,
            "isStar": 0,
            "name": folder.name,
            "parentId": getters.currentFolder?.id  || 0,
            "storeType": router.currentRoute.name === 'Files' ? 1: 0,
            "tags": "",
            // "userId": getters.user.id,
        }).then((response) => {
            commit('ADD_NEW_ITEM', response)

            events.$emit('scrollTop')

            // Set focus on new folder name
            setTimeout(() => {
                if (!Vue.prototype.$isMobile()) {
                    events.$emit('newFolder:focus', response.id)
                }
            }, 10)

            // Refresh folder tree navigation
            dispatch('getFolderTree')
        })
        .catch((error) => {
            events.$emit('alert:open', {
                title: error.response.data.message || i18n.t('popup_error.title'),
                message: i18n.t('popup_error.message'),
            })
        });
    },
    renameItem: ({ commit, getters, dispatch }, data) => {
        // Updated name in favourites panel
        if (data.type === 0 && getters.user)
            commit('UPDATE_NAME_IN_FAVOURITES', data)

        // Get route
        let route = {
            RequestUpload: `/api/file-request/${router.currentRoute.params.token}/rename/${data.id}`,
            Public: `/api/sharing/rename/${data.id}/${router.currentRoute.params.token}`,
        }[router.currentRoute.name] || `/api/rename/${data.id}`

        let requestData = {
            ...data,
            userId: getters.user.id,
        };

        if (typeof requestData.tags !== 'undefined' && Array.isArray(requestData.tags)) {
            requestData.tags = requestData.tags.join(',');
        }

        if (router.currentRoute.name === 'DraftFiles') {
            Api.draft.saveDraft(requestData).then((response) => {
                commit('CHANGE_ITEM_NAME', response)
    
                if (data.type === 0 && router.currentRoute.name !== 'Public')
                    dispatch('getAppData')
                if (data.type === 0 && router.currentRoute.name === 'Public')
                    dispatch('getFolderTree')
            })
            .catch(() => Vue.prototype.$isSomethingWrong());
        } else {
            Api.netdisk.saveFile(requestData).then((response) => {
                commit('CHANGE_ITEM_NAME', response)
    
                if (data.type === 0 && router.currentRoute.name !== 'Public')
                    dispatch('getAppData')
                if (data.type === 0 && router.currentRoute.name === 'Public')
                    dispatch('getFolderTree')
            })
            .catch(() => Vue.prototype.$isSomethingWrong());
        }

    },
    uploadFiles: ({ commit, getters, dispatch }, { form, fileSize, totalUploadedSize }) => {
        return new Promise((resolve, reject) => {
            // Get route
            let route = {
                RequestUpload: `/api/file-request/${router.currentRoute.params.token}/upload/chunks`,
                Public: `/api/sharing/upload/chunks/${router.currentRoute.params.token}`,
            }[router.currentRoute.name] || '/api/upload/chunks'

            // Create cancel token for axios cancellation
            const CancelToken = axios.CancelToken,
                source = CancelToken.source()

            axios
                .post(route, form, {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/octet-stream',
                    },
                    onUploadProgress: (event) => {
                        let percentCompleted = Math.floor(((totalUploadedSize + event.loaded) / fileSize) * 100)

                        commit('UPLOADING_FILE_PROGRESS', percentCompleted >= 100 ? 100 : percentCompleted)

                        // Set processing file
                        if (percentCompleted >= 100) commit('PROCESSING_FILE', true)
                    },
                })
                .then(async (response) => {
                    resolve(response)

                    // Proceed if was returned database record
                    if (response.data.data.id) {
                        commit('PROCESSING_FILE', false)

                        commit('INCREASE_FOLDER_ITEM', response.data.data.attributes.parent_id)

                        // Remove first file from file queue
                        commit('SHIFT_FROM_FILE_QUEUE')

                        // Refresh request detail to update currentFolder in Vuex
                        if (router.currentRoute.name === 'RequestUpload' && !getters.currentFolder) {
                            await dispatch('getUploadRequestDetail')
                        }

                        // Check if user is in uploading folder, if yes, than show new file
                        if (
                            (!getters.currentFolder && !response.data.data.attributes.parent_id) ||
                            (getters.currentFolder &&
                                response.data.data.attributes.parent_id === getters.currentFolder.data.id)
                        ) {
                            // Add uploaded item into view
                            commit('ADD_NEW_ITEM', response.data)
                        }

                        // Reset file progress
                        commit('UPLOADING_FILE_PROGRESS', 0)

                        // Increase count in files in queue uploaded for 1
                        commit('INCREASE_FILES_IN_QUEUE_UPLOADED')

                        // Start uploading next file if file queue is not empty
                        if (getters.fileQueue.length) {
                            Vue.prototype.$handleUploading(getters.fileQueue[0])
                        }

                        // Reset upload process
                        if (!getters.fileQueue.length) {
                            commit('CLEAR_UPLOAD_PROGRESS')

                            // Reload File data after folder uploading is finished
                            if (getters.isUploadingFolder) {
                                commit('START_LOADING_VIEW')

                                // Reload files after folder upload is done
                                Vue.prototype.$getDataByLocation(1)

                                // Reload folder tree
                                dispatch('getFolderTree')

                                commit('UPDATE_UPLOADING_FOLDER_STATE', false)
                            }
                        }
                    }
                })
                .catch((error) => {
                    reject(error)

                    let messages = {
                        423: {
                            title: i18n.t('popup_exceed_limit.title'),
                            message: i18n.t('popup_exceed_limit.message'),
                        },
                        422: {
                            title: i18n.t('popup_mimetypes_blacklist.title'),
                            message: i18n.t('popup_mimetypes_blacklist.message'),
                        },
                        413: {
                            title: i18n.t('popup_paylod_error.title'),
                            message: i18n.t('popup_paylod_error.message'),
                        },
                        401: {
                            title: error.response.data.message,
                        },
                    }

                    if (messages[error.response.status]) {
                        events.$emit('alert:open', {
                            emoji: '😬😬😬',
                            title: messages[error.response.status]['title'] || null,
                            message: messages[error.response.status]['message'] || null,
                        })
                    }

                    commit('PROCESSING_FILE', false)
                    commit('CLEAR_UPLOAD_PROGRESS')
                })

            // Cancel the upload request
            events.$on('cancel-upload', () => {
                source.cancel()

                // Hide upload progress bar
                commit('PROCESSING_FILE', false)
                commit('CLEAR_UPLOAD_PROGRESS')
            })
        })
    },
    restoreItem: ({ commit, getters }, item) => {
        let items = item
            ? [item]
            : getters.clipboard

        let restoreToHome = Vue.prototype.$isThisRoute(router.currentRoute, ['Trash'])

        let itemToRestore = items.map((data) => data.trashId);

        // Remove file preview
        commit('CLIPBOARD_CLEAR')

        Api.netdisk.restoreRecycle({
            ids: itemToRestore
        })
        .then(() => items.forEach((item) => commit('REMOVE_ITEM', item.id)))
        .catch((e) => Vue.prototype.$isSomethingWrong());
    },
    deleteItem: ({ commit, getters, dispatch }, item) => {
        let items = item
            ? [item]
            : getters.clipboard

        MessageBox.confirm(router.currentRoute.name === 'Trash' ? '是否真的要删除这些文件？' : '删除后将不可恢复，确认删除这些文件吗？', '提示', {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(_ => {
            let deletedItems = items.map((data) => {
                // Remove file from view
                commit('REMOVE_ITEM', data.id)
                commit('REMOVE_ITEM_FROM_CLIPBOARD', data.id)
                events.$emit('file:deleted', data.id)
    
                // Remove item from sidebar
                // if (! ['Public', 'RequestUpload'].includes(router.currentRoute.name) && data.type === 0)
                //     commit('REMOVE_ITEM_FROM_FAVOURITES', data)
    
                return {
                    // force_delete: 1,
                    id: data.trashId ? data.trashId : data.id,
                    blocId: getters.user.blocId,
                    fileStoreId: getters.user.fileStoreId,
                    storeType: data.storeType,
                    userId: getters.user.id
                }
            })

            // Get route
            let route = {
                RequestUpload: `/api/file-request/${router.currentRoute.params.token}/remove`,
                Public: `/api/sharing/remove/${router.currentRoute.params.token}`,
            }[router.currentRoute.name] || '/api/remove'

            let ids = deletedItems.map(item => item.id).join();

            let result = null;
            if (router.currentRoute.name === 'DraftFiles') {
                result = Api.draft.deleteDraft({
                    ids: ids.split(','),
                });
            } else {
                if (items.find(item => typeof item.trashId !== 'undefined')) {
                    // 彻底删除
                    result = Api.netdisk.deleteRecycle({
                        ids: ids.split(','),
                        // id: ids,
                        // blocId: getters.user.blocId,
                        // fileStoreId: getters.user.fileStoreId,
                        // userId: getters.user.id
                    });
                } else {
                    // 放到回收站
                    result = Api.netdisk.moveToRecycle({
                        ids,
                        // id: ids,
                        blocId: getters.user.blocId,
                        fileStoreId: getters.user.fileStoreId,
                        userId: getters.user.id,
                        storeType: router.currentRoute.name === 'Files' ? 1: 0,
                    });
                }
            }

            
            result.then(() => {
                deletedItems.forEach((data) => {
                    // If is folder, update app data
                    if (data.type === 0 && getters.currentFolder && data.id === getters.currentFolder.data.id) {
                        router.back()
                    }
                })

                if (router.currentRoute.name !== 'DraftFiles') {
                    // Refresh folder tree navigation
                    dispatch('getFolderTree')
                }
            })
            .catch(() => Vue.prototype.$isSomethingWrong())
        });
    },
    emptyTrash: ({ commit, getters }) => {
        // Clear file browser
        commit('START_LOADING_VIEW')

        axios
            .post(getters.api + '/trash/dump', {
                _method: 'delete',
            })
            .then(() => {
                commit('STOP_LOADING_VIEW')
                events.$emit('scrollTop')

                commit('CLIPBOARD_CLEAR')
            })
            .catch(() => Vue.prototype.$isSomethingWrong())
    },
    emptyTrashQuietly: ({ commit, getters }) => {
        axios
            .post(getters.api + '/trash/dump', {
                _method: 'delete',
            })
            .then(() => {
                if (router.currentRoute.name === 'Trash') {
                    commit('STOP_LOADING_VIEW')
                }

                events.$emit('toaster', {
                    type: 'success',
                    message: i18n.t('Your trash was successfully cleared.'),
                })
            })
            .catch(() => Vue.prototype.$isSomethingWrong())
    },
    pushFileToTheUploadQueue: ({commit, getters}, item) => {
        // Prevent to upload file with 0kb file size
        if (item.file.size === 0) {
            events.$emit('toaster', {
                type: 'danger',
                message: `The file ${item.file.name} can't be uploaded`,
            })
        }

        if (item.file.size !== 0 && item.file.name !== '.DS_Store') {
            // commit file to the upload queue
            commit('ADD_FILES_TO_QUEUE', item)

            // Start uploading if uploading process isn't running
            if (getters.filesInQueueTotal === 0) {
                Vue.prototype.$handleUploading(getters.fileQueue[0])
            }

            // Increase total files in upload bar
            commit('INCREASE_FILES_IN_QUEUES_TOTAL')
        }
    }
}

const mutations = {
    UPDATE_UPLOADING_FOLDER_STATE(state, status) {
        state.isUploadingFolder = status
    },
    PROCESSING_POPUP(state, status) {
        state.processingPopup = status
    },
    ADD_FILES_TO_QUEUE(state, file) {
        state.fileQueue.push(file)
    },
    SHIFT_FROM_FILE_QUEUE(state) {
        state.fileQueue.shift()
    },
    PROCESSING_FILE(state, status) {
        state.isProcessingFile = status
    },
    UPLOADING_FILE_PROGRESS(state, percentage) {
        state.uploadingProgress = percentage
    },
    INCREASE_FILES_IN_QUEUES_TOTAL(state) {
        state.filesInQueueTotal += 1
    },
    INCREASE_FILES_IN_QUEUE_UPLOADED(state) {
        state.filesInQueueUploaded++
    },
    CLEAR_UPLOAD_PROGRESS(state) {
        state.filesInQueueUploaded = 0
        state.filesInQueueTotal = 0
        state.fileQueue = []
    },
}

const getters = {
    filesInQueueUploaded: (state) => state.filesInQueueUploaded,
    filesInQueueTotal: (state) => state.filesInQueueTotal,
    uploadingProgress: (state) => state.uploadingProgress,
    isUploadingFolder: (state) => state.isUploadingFolder,
    isProcessingFile: (state) => state.isProcessingFile,
    processingPopup: (state) => state.processingPopup,
    fileQueue: (state) => state.fileQueue,
}

export default {
    state: defaultState,
    mutations,
    actions,
    getters,
}
