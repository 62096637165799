const routesFile = [
    {
        name: 'Platform',
        path: '/platform',
        component: () => import(/* webpackChunkName: "chunks/platform" */ '../views/Platform'),
        children: [
            {
                name: 'UploadFile',
                path: '/platform/:type/upload-file/:id?',
                component: () =>
                    import(/* webpackChunkName: "chunks/upload-file" */ '../views/FileView/UploadFile'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'Files',
                path: '/platform/files/:id?',
                component: () => import(/* webpackChunkName: "chunks/files" */ '../views/FileView/Files'),
                meta: {
                    requiresAuth: true,
                    title: '个人网盘'
                },
            },
            {
                name: 'PendingAduitFiles',
                path: '/platform/pending-aduit-files/:id?',
                component: () => import(/* webpackChunkName: "chunks/pending-aduit-files" */ '../views/FileView/PendingAduitFiles'),
                meta: {
                    requiresAuth: true,
                    title: '待审核文件'
                },
            },
            {
                name: 'StarFiles',
                path: '/platform/star-files/:id?',
                component: () => import(/* webpackChunkName: "chunks/star-files" */ '../views/FileView/StarFiles'),
                meta: {
                    requiresAuth: true,
                    title: '精选标星'
                },
            },
            {
                name: 'DraftFiles',
                path: '/platform/draft-files/:id?',
                component: () => import(/* webpackChunkName: "chunks/draft-files" */ '../views/FileView/DraftFiles'),
                meta: {
                    requiresAuth: true,
                    title: '草稿箱'
                },
            },
            {
                name: 'RecentUploads',
                path: '/platform/recent-uploads',
                component: () =>
                    import(/* webpackChunkName: "chunks/recent-uploads" */ '../views/FileView/RecentUploads'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'MySharedItems',
                path: '/platform/my-shared-items',
                component: () =>
                    import(/* webpackChunkName: "chunks/my-shared-items" */ '../views/FileView/MySharedItems'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'Trash',
                path: '/platform/trash/:id?',
                component: () => import(/* webpackChunkName: "chunks/trash" */ '../views/FileView/Trash'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'TeamFolders',
                path: '/platform/team-folders/:id?',
                component: () => import(/* webpackChunkName: "chunks/team-folders" */ '../views/FileView/TeamFolders'),
                meta: {
                    requiresAuth: true,
                    title: '工作区'
                },
            },
            {
                name: 'SharedWithMe',
                path: '/platform/shared-with-me/:id?',
                component: () =>
                    import(/* webpackChunkName: "chunks/shared-with-me" */ '../views/FileView/SharedWithMe'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                name: 'Tags',
                path: '/platform/tags',
                component: () =>
                    import(/* webpackChunkName: "chunks/tag" */ '../views/FileView/Tags'),
                meta: {
                    requiresAuth: true,
                    title: '标签管理'
                },
            },
        ],
    },
]

export default routesFile
