import routesUploadRequest from './routesUploadRequest'
import routesMaintenance from './routesMaintenance'
import routesShared from './routesShared'
import routesOthers from './routesOthers'
import routesAdmin from './routesAdmin'
import routesIndex from './routesIndex'
import routesAuth from './routesAuth'
import routesUser from './routesUser'
import routesSite from './routesSite'
import routesFile from './routesFile'
import routesTeam from './routesTeam'
import routesLog from './routesLog'
import routesSuperAdmin from './routesSuperAdmin'
import store from '../store/index'
import Router from 'vue-router'
import Vue from 'vue'
import config from '../config'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: [
        ...routesUploadRequest,
        ...routesMaintenance,
        ...routesShared,
        ...routesOthers,
        ...routesAdmin,
        ...routesIndex,
        ...routesAuth,
        ...routesUser,
        ...routesSite,
        ...routesFile,
        ...routesTeam,
        ...routesLog,
        ...routesSuperAdmin
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        let isAuthenticated = store.getters.config ? store.getters.config.isAuthenticated : config.isAuthenticated

        if (!isAuthenticated) {
            next({
                name: 'SignIn',
                query: { redirect: to.fullPath },
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
