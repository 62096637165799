import router from '../../router'
import {events} from '../../bus'
import i18n from '../../i18n'
import axios from 'axios'
import Vue from 'vue'
import Api from '../../api'

const defaultState = {
	currentTeamFolder: undefined,
}

const actions = {
	getDraftFolder: ({commit, getters}, {page, id}) => {
		return new Promise((resolve, reject) => {
			if (page === 1)
				commit('START_LOADING_VIEW')

			if (typeof id === 'undefined')
				commit('SET_CURRENT_TEAM_FOLDER', null)

				let requestData = {
					pageNumber: page,
				};
				
				Api.draft.getDraftList(requestData).then((response) => {
					// commit('SET_CURRENT_FOLDER', response.data.meta.root)
					// commit('SET_PAGINATOR', response.data.meta.paginate)
					// commit('STOP_LOADING_VIEW')
					// commit('ADD_NEW_ITEMS', response.data.data)

					// if (id) {
					// 	Api.netdisk.getFileDetail({
					// 		id
					// 	}).then(res => {
					// 		commit('SET_CURRENT_FOLDER', res)

					// 		if (
					// 			!getters.currentTeamFolder ||
					// 			getters.currentTeamFolder.id !== id
					// 		) {
					// 			commit('SET_CURRENT_TEAM_FOLDER', res)
					// 		}
					// 	});
					// } else {
					// 	commit('SET_CURRENT_FOLDER', 0)
					// }

					response.records = response.records.map(item => {
						return {
							...item,
							type: 0,
							name: item.title
						};
					});

					commit('SET_PAGINATOR', response)
					commit('STOP_LOADING_VIEW')
					commit('ADD_NEW_ITEMS', response.records)

					events.$emit('scrollTop')

					resolve(response)
				})
				.catch((error) => {
					// Redirect if unauthenticated
					if ([401, 403].includes(error.response.status)) {
						commit('SET_AUTHORIZED', false)
						router.push({name: 'SignIn'})
					} else {
						// Show error message
						events.$emit('alert:open', {
							title: i18n.t('popup_error.title'),
							message: i18n.t('popup_error.message'),
						})
					}

					reject(error)
				})
		})
	},
	getTeamFolder: ({commit, getters}, data) => {
		return new Promise((resolve, reject) => {
			let {page, id} = data;
			if (page === 1)
				commit('START_LOADING_VIEW')

			if (typeof id === 'undefined')
				commit('SET_CURRENT_TEAM_FOLDER', null)
				delete data.page;
				delete data.id;

				let requestData = {
					pageNumber: page,
					parentId: id || undefined,
					storeType: 0,
					...data,
					sortType: getters.sorting.field,
					sortOrder: getters.sorting.sort,
				};
				if (router.currentRoute.name === 'PendingAduitFiles') {
					requestData.auditStatus = 0;
				}
				if (router.currentRoute.name === 'StarFiles') {
					requestData.isStar = 1;
				}

				if (typeof data.searchFolderType !== 'undefined') {
					// 包含子目录搜索
					if (data.searchFolderType === 'sub') {
						delete requestData.parentId;
					}
					delete requestData.searchFolderType;
				}
				
				Api.netdisk.getFolder(requestData).then((response) => {
					// commit('SET_CURRENT_FOLDER', response.data.meta.root)
					// commit('SET_PAGINATOR', response.data.meta.paginate)
					// commit('STOP_LOADING_VIEW')
					// commit('ADD_NEW_ITEMS', response.data.data)

					if (getters.selectFolder && getters.selectFolder.id === id) {
						commit('SET_CURRENT_FOLDER', JSON.parse(JSON.stringify(getters.selectFolder)))
						if (
							!getters.currentTeamFolder ||
							getters.currentTeamFolder.id !== id
						) {
							commit('SET_CURRENT_TEAM_FOLDER', JSON.parse(JSON.stringify(getters.selectFolder)))
						}
					} else if (id && parseFloat(id) !== 0) {
						Api.netdisk.getFileDetail({
							id
						}, {
							cancelToken: new axios.CancelToken(e => {
								events.$on('changeMainNavMenu', function() {
									e();
								});
							})
						}).then(res => {
							commit('SET_CURRENT_FOLDER', res)

							if (
								!getters.currentTeamFolder ||
								getters.currentTeamFolder.id !== id
							) {
								commit('SET_CURRENT_TEAM_FOLDER', res)
							}
						});
					} else {
						commit('SET_CURRENT_FOLDER', 0)
					}

					response.records = response.records.map(item => {
						return {
							...item,
							tags: item.tags ? item.tags.split(',') : []
						};
					});

					commit('SET_PAGINATOR', response)
					commit('STOP_LOADING_VIEW')
					commit('ADD_NEW_ITEMS', response.records)

					events.$emit('scrollTop')

					resolve(response)
				})
				.catch((error) => {
					// Redirect if unauthenticated
					if ([401, 403].includes(error.response.status)) {
						commit('SET_AUTHORIZED', false)
						router.push({name: 'SignIn'})
					} else {
						// Show error message
						events.$emit('alert:open', {
							title: i18n.t('popup_error.title'),
							message: i18n.t('popup_error.message'),
						})
					}

					reject(error)
				})
		})
	},
	getSharedWithMeFolder: ({commit, getters}, {page, id}) => {
		return new Promise((resolve, reject) => {
			if (page === 1)
				commit('START_LOADING_VIEW')

			if (typeof id === 'undefined') {
				commit('SET_CURRENT_TEAM_FOLDER', null)
			}

			axios
				.get(`${getters.api}/teams/shared-with-me/${id || 'all'}${getters.sorting.URI}&page=${page}`)
				.then((response) => {
					commit('SET_CURRENT_FOLDER', response.data.meta.root)
					commit('SET_PAGINATOR', response.data.meta.paginate)
					commit('STOP_LOADING_VIEW')
					commit('ADD_NEW_ITEMS', response.data.data)

					if (
						!getters.currentTeamFolder ||
						getters.currentTeamFolder.data.id !== response.data.meta.teamFolder.data.id
					) {
						commit('SET_CURRENT_TEAM_FOLDER', response.data.meta.teamFolder)
					}

					events.$emit('scrollTop')

					resolve(response)
				})
				.catch((error) => {
					// Redirect if unauthenticated
					if ([401, 403].includes(error.response.status)) {
						commit('SET_AUTHORIZED', false)
						router.push({name: 'SignIn'})
					} else {
						// Show error message
						events.$emit('alert:open', {
							title: i18n.t('popup_error.title'),
							message: i18n.t('popup_error.message'),
						})
					}

					reject(error)
				})
		})
	},
	getTeamFolderTree: ({commit, getters}) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`/api/teams/folders/${getters.currentTeamFolder.data.id}/tree${getters.sorting.URI}`)
				.then((response) => {
					resolve(response)

					commit('UPDATE_FOLDER_TREE', response.data)
				})
				.catch((error) => {
					reject(error)

					Vue.prototype.$isSomethingWrong()
				})
		})
	},
}

const mutations = {
	SET_CURRENT_TEAM_FOLDER(state, payload) {
		state.currentTeamFolder = payload
	},
}

const getters = {
	currentTeamFolder: (state) => state.currentTeamFolder,
}

export default {
	state: defaultState,
	getters,
	actions,
	mutations,
}
