const auth = {
    inserted(el, binding) {
        const { value } = binding;
        if (value) {
            try {
            const { authorityLevel } = document.getElementById("app").__vue__.$store.getters.user;
            const hasAuth =
                value && Array.isArray(value)
                ? value.some((v) => [authorityLevel].includes(v))
                : [authorityLevel].includes(value);
            if (!hasAuth) {
                el.style.display = "none";
            }
            } catch (e) {
            throw new Error(
                `need roles! Like v-auth="['admin','test']" or v-auth="'admin'" or v-auth="1"`
            );
            }
        } else {
            throw new Error(
            `need roles! Like v-auth="['admin','test']" or v-auth="'admin'" or v-auth="1"`
            );
        }
    }
  }
  
  export default auth