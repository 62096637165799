let config = {
    host: 'https://demo-metered.vuefilemanager.com',
    api: 'https://demo-metered.vuefilemanager.com/api',
    locale: 'en',

    kkFileViewHost: 'http://47.106.189.235:8012',

    broadcasting: 'pusher',
    broadcastingKey: 'local',
    broadcastingHost: 'socket.vuefilemanager.com',
    broadcastingPort: '',
    broadcastingCluster: 'mt1',

    app_logo: 'system/logo.svg',
    app_logo_dark: 'system/logo-dark.svg',
    app_logo_horizontal: 'system/logo-horizontal.svg',
    app_logo_horizontal_dark: 'system/logo-horizontal-dark.svg',

    app_color: '#00A0E8',
    app_footer: '© 2021 Simple & Powerful Personal Cloud Storage. Developed by <a href="https://hi5ve.digital" target="_blank" class="text-theme">Hi5Ve.Digital</a>',
    app_name: 'VueFileManager',
    app_description: 'Your self-hosted storage cloud software powered by Laravel and Vue',

    allowHomepage: 1,
    storageLimit: 1,
    teamsDefaultMembers: 10,
    storageDefaultSpace: 5,
    storageDefaultSpaceFormatted: '5GB',
    mimetypesBlacklist: '',
    uploadLimit: 1000000000,
    uploadLimitFormatted: '1GB',
    chunkSize: 64000000,

    isAuthenticated: 1,

    isDev: 0,
    isDemo: 1,

    legal: [{"slug":"terms-of-service","title":"Terms of Service","visibility":1,"content":"Laoreet cum hendrerit iaculis arcu phasellus congue et elementum, pharetra risus imperdiet aptent posuere rutrum parturient blandit, dapibus tellus ridiculus potenti aliquam sociis turpis. Nullam commodo eget laoreet risus cursus vel placerat, in dapibus sociis gravida faucibus sodales, fringilla potenti elit semper iaculis ullamcorper. Dignissim vulputate pretium montes pellentesque mollis, consectetur adipiscing curabitur semper sem rhoncus, litora viverra curae proin."},{"slug":"privacy-policy","title":"Privacy Policy","visibility":1,"content":"Sit orci justo augue maecenas laoreet consectetur natoque magnis in viverra sagittis, himenaeos urna facilisis mus proin primis diam accumsan tristique inceptos. Primis quisque posuere sit praesent lobortis feugiat semper convallis facilisis, vivamus gravida ligula nostra curae eu donec duis parturient senectus, arcu dolor viverra penatibus natoque cum nisi commodo. Litora sociis mauris justo nullam suspendisse mattis maecenas nascetur congue phasellus cras ultricies posuere donec, dapibus egestas diam lacus ornare montes senectus tincidunt eu taciti sed consequat."},{"slug":"cookie-policy","title":"Cookie Policy","visibility":1,"content":"Metus penatibus ligula dolor natoque non habitasse laoreet facilisis, libero vivamus eget semper vulputate interdum integer, phasellus lorem enim blandit consectetur nullam sollicitudin. Hendrerit interdum luctus ut in molestie himenaeos eros cum laoreet parturient est, eu lectus hac et netus viverra dictumst congue elit sem senectus litora, fames scelerisque adipiscing inceptos fringilla montes sociosqu suscipit auctor potenti. Elementum lacus vulputate viverra ac morbi ligula ipsum facilisi, sit eu imperdiet lacinia congue dis vitae."}],

    installation: 'installation-done',
    statusCheck: [],
    isSetupWizardDemo: 0,
    isSetupWizardDebug: 0,
    isPrefilledUsers: 1,

    // States
    isEmptyPlans: 0,
    isEmptyTransactions: 0,
    isEmptySubscriptions: 0,

    // Hidden set ups
    isAdminVueFileManagerBar: 1,

    // Metered
    allowed_registration_bonus: 1,
    registration_bonus_amount: 10,
    isCreatedMeteredPlan: 1,
    meteredPlanId: 'e71e9547-7146-48f6-936d-c30f6c3aca1d',

    // Payments
    allowed_payments: 1,
    subscriptionType: 'metered',

    // PayPal
    isPayPal: 1,
    isPayPalLive: 0,
    paypal_client_id: 'Ac_ECj7HlQgkrfjVkv-sL44sIvOnTmgAWcM59figQffWiLIVOi70a7lcbEr0XwyEb9mPTj7jWQBWPP_m',
    paypal_payment_description: 'Available PayPal Credit, Debit or Credit Card.',

    // Paystack
    isPaystack: 1,
    paystack_public_key: 'pk_test_98f24dac5445ff1d87a64b16f166f15b144b5807',
    paystack_payment_description: 'Available Bank Account, USSD, Mobile Money, Apple Pay.',

    // Stripe
    isStripe: 1,
    stripe_public_key: 'pk_test_51KaMTOKWmN85p3v7qViAvPn6cwCUQ4BsCS32WqAsgNVnxMdmCzmnShFawkqbCWoeK0NfC6AERm0yTCXQ9QVPUlOx00Zp5kSRsz',
    stripe_payment_description: 'Available credit card or Apple Pay.',

    // ReCaptcha
    recaptcha_client_id: '6LfL6jceAAAAAL3EFILH0xNlq55VIK48O9bXFk-g',
    allowedRecaptcha: 1,
    isRecaptchaConfigured: 1,

    // Social logins
    allowedFacebookLogin: 1,
    isFacebookLoginConfigured: 1,

    allowedGoogleLogin: 1,
    isGoogleLoginConfigured: 1,

    allowedGithubLogin: 1,
    isGithubLoginConfigured: 1,

    // Adsense
    allowedAdsense: 0,
    adsenseClientId: '',
    adsenseBanner01: ``,
    adsenseBanner02: ``,
    adsenseBanner03: ``,

    // User settings
    defaultEmoji: 'twemoji',
    defaultThemeMode: 'system',

    // App settings
    userRegistration: 1,
    userVerification: 0,
}

let env = 'production';        // env环境，dev-本地开发环境，test-线上测试环境，staging-预发布环境，production-生产环境
let apiPath = '';
let basePath = '';
let kkFileViewHost = '';

if (env === 'dev') {
  apiPath = '/image_lib_api/';
  basePath = '/tuku_pc/';
  kkFileViewHost = 'http://47.106.189.235:8012';
} else if (env === 'production') {
  apiPath = '//tk1-api.gdskfz.com/';
  basePath = '/';
  kkFileViewHost = 'http://tk1.gdskfz.com:8012';
}

export default {
    ...config,
    apiPath,
    basePath,
    kkFileViewHost
};