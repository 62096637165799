<template>
  <div id="app" @keydown.esc="closeOverlays" tabindex="-1" class="min-w-[320px]">
      <!--UI components-->
      <Alert />
      <ToasterWrapper />
  <RemoteUploadProgress />

      <!--Show spinner before translations is loaded-->
      <Spinner v-if="!isLoaded" />

      <!--Show warning bar when user functionality is restricted-->
      <RestrictionWarningBar />

  <div>
    <router-view v-if="isLoaded" />
  </div>

      <!--Background under popups-->
      <Vignette />
  </div>
</template>

<script>
import RestrictionWarningBar from './components/Subscription/RestrictionWarningBar'
import RemoteUploadProgress from "./components/RemoteUpload/RemoteUploadProgress"
import ToasterWrapper from './components/Toaster/ToasterNotifications'
import Spinner from './components/UI/Others/Spinner'
import Vignette from './components/UI/Others/Vignette'
import Alert from './components/Popups/Alert'
import { mapGetters } from 'vuex'
import { events } from './bus'

export default {
  name: 'App',
  components: {
      RestrictionWarningBar,
  RemoteUploadProgress,
      ToasterWrapper,
      Vignette,
      Spinner,
      Alert,
  },
  data() {
      return {
          isLoaded: false,
    isSidebarNavigation: undefined,
      }
  },
  computed: {
      ...mapGetters(['config', 'user']),
  },
  watch: {
      'config.defaultThemeMode': function () {
          this.handleDarkMode()
      },
  '$route' () {
    let section = this.$router.currentRoute.fullPath.split('/')[1]
    const app = document.getElementsByTagName('body')[0]

    // Set background color via theme setup
    // if (['admin', 'user'].includes(section)) {
    //   app.classList.add('dark:bg-dark-background', 'bg-light-background')
    // } else {
    //   app.classList.remove('dark:bg-dark-background', 'bg-light-background')
    // }
  }
  },
  methods: {
  closeOverlays() {
    events.$emit('popup:close')
    events.$emit('popover:close')

    this.$store.commit('CLOSE_NOTIFICATION_CENTER')
  },
      spotlightListener(e) {
    if (e.key === 'k' && e.metaKey || e.key === 'k' && e.ctrlKey) {
      e.preventDefault()
      events.$emit('spotlight:show');
    }
      },
      handleDarkMode() {
          const app = document.getElementsByTagName('html')[0]
          const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')

          if (this.config.defaultThemeMode === 'dark') {
              app.classList.add('dark')
              this.$store.commit('UPDATE_DARK_MODE_STATUS', true)
          } else if (this.config.defaultThemeMode === 'light') {
              app.classList.remove('dark')
              this.$store.commit('UPDATE_DARK_MODE_STATUS', false)
          } else if (this.config.defaultThemeMode === 'system' && prefersDarkScheme.matches) {
              app.classList.add('dark')
              this.$store.commit('UPDATE_DARK_MODE_STATUS', true)
          } else if (this.config.defaultThemeMode === 'system' && !prefersDarkScheme.matches) {
              app.classList.remove('dark')
              this.$store.commit('UPDATE_DARK_MODE_STATUS', false)
          }
      },
  },
  beforeMount() {
    //   window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    //       this.handleDarkMode()
    //   })

      // Commit config
      this.$store.commit('INIT', {
          config: this.$root.$data.config,
      })

      // get website info
      this.$store.dispatch('getWebsiteInfo', {});
      if (this.user) {
        this.$store.dispatch('getUserInfo', {});
      }

      // Redirect to setup wizard
      if (this.$root.$data.config.installation === 'installation-needed') {
          this.isLoaded = true

          if (window.location.pathname.split('/')[1] !== 'setup-wizard') {
              this.$router.push({ name: 'StatusCheck' })
          }
      } else {
          this.$store.dispatch('getLanguageTranslations', this.$root.$data.config.locale).then(() => {
              this.isLoaded = true
          })
      }

  // Go to sign in page if homepage is disabled
  if (!this.$root.$data.config.allowHomepage && window.location.pathname === '/') {
    this.$router.push({ name: 'SignIn' })
  }
  },
  created() {
      if (this.$isWindows()) {
          document.body.classList.add('windows')
      }

      window.addEventListener('keydown', this.spotlightListener)
  },
  destroyed() {
      window.removeEventListener('keydown', this.spotlightListener)
  }
}
</script>

<style>
  .router-link-active .icon-active {
      color: #00A0E8;
  }
  .router-link-active .text-active {
      color: #00A0E8;
  }

  
  .group:hover:not(.hover-disabled) .group-hover-text-theme {color: #00A0E8 !important;}

  
  .svg-color-theme {
      fill: #00A0E8;
      stroke: #00A0E8;
  }
  .svg-color-theme-darken {
      fill: #00A0E8;
      stroke: #00A0E8;
      filter: brightness(0.80);
  }

  .svg-stroke-theme {stroke: #00A0E8}
  .hover-svg-stroke-theme:hover {stroke: #00A0E8}

  .hover-svg-stroke-theme:hover rect {stroke: #00A0E8}
  .hover-svg-stroke-theme:hover line {stroke: #00A0E8}

  .bg-theme {background: #00A0E8}
  .bg-theme-50 {background: #00A0E805}
  .bg-theme-100 {background: #00A0E810}
  .bg-theme-200 {background: #00A0E820}
  .bg-theme-800 {background: #00A0E880}
  .hover-bg-theme:hover {background: #00A0E8}
  .hover-bg-theme-100:hover {background: #00A0E810 !important;}

  .text-theme {color: #00A0E8}
  .text-theme-darken {
      color: #00A0E8;
      filter: brightness(0.75);
  }

  .hover-text-theme:hover {color: #00A0E8}

  .shadow-theme {box-shadow: 0 10px 15px -3px #00A0E840}

  .border-theme {border-color: #00A0E8}
  .border-top-theme {border-top-color: #00A0E8 !important;}
  .border-left-theme {border-left-color: #00A0E8 !important;}
  .hover-border-theme:hover {border-color: #00A0E8 !important;}

  .focus-within-border-theme:focus-within {border-color: #00A0E8 !important;}

  .focus-border-theme:focus {border-color: #00A0E8 !important}
  .focus-border-theme:focus[type='email'] {border-color: #00A0E8}
  .focus-border-theme:focus[type='text'] {border-color: #00A0E8}
  .focus-border-theme:focus[type='password'] {border-color: #00A0E8}
  .focus-border-theme:focus[type='number'] {border-color: #00A0E8}

  
  .active.active-bg-theme-100 {background: #00A0E810 !important;}
  .active.active-border-theme {border-color: #00A0E8 !important;}
  .active .active-text-theme {color: #00A0E8}

  
  .active-menu.focus-border-theme {border-color: #00A0E8 !important;}

  
  .router-link-active.home .button-icon {background: #00A0E810}

  
  .router-link-active .text-theme {color: #00A0E8 !important}
  .router-link-active .text-theme svg {color: #00A0E8 !important}
  .router-link-active.border-bottom-theme {border-bottom-color: #00A0E8 !important;}

  .is-active .text-theme {color: #00A0E8 !important}
  .is-active .text-theme svg {color: #00A0E8 !important}

  .menu-list-item.link:hover {color: #00A0E8}
  .menu-list-item.link:hover .text-theme svg {color: #00A0E8}

  
  .input-area.is-active {border-color: #00A0E8 !important}

  
  .button-base.theme-solid {background: #00A0E8}
  .button-base.theme-solid polyline,
  .button-base.theme-solid path {
      color: white;
  }


  .button-base.theme {background: #00A0E810}
  .button-base.theme {color: #00A0E8}
  .button-base.theme .content {color: #00A0E8}

  .button-base.theme polyline,
  .button-base.theme path {
      color: #00A0E8
  }

  .switch.active {background: #00A0E8 !important;}


  
  .border-theme {border-color: #00A0E8 !important;}
  .folder-item.is-dragenter {border-color: #00A0E8 !important;}
  .favourites.is-dragenter .menu-list {border-color: #00A0E8 !important;}

  
  .StripeElement--focus {border-color: #00A0E8 !important;}

  
  .dark .dark-text-theme {color: #00A0E8 !important;}

  .dark .text-theme {
      color: #00A0E8
  }
</style>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
@import 'assets/sass/vuefilemanager/variables';
@import 'assets/sass/vuefilemanager/mixins';

input:-webkit-autofill {
  transition-delay: 999999999999s;
}

[v-cloak],
[v-cloak] > * {
  display: none;
}

* {
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//   font-size: 16px;
  text-decoration: none;
  color: $text;
}

.vue-feather {
  path,
  circle,
  line,
  rect,
  polyline,
  ellipse,
  polygon {
      color: inherit;
  }
}

// Dark mode
.dark {
  * {
      color: $dark_mode_text_primary;
  }

  body,
  html {
      background: $dark_mode_background;
      color: $dark_mode_text_primary;

      img {
          opacity: 0.95;
      }
  }
}
</style>
