<template>
    <div class="absolute bottom-0 left-0 right-0 flex items-center space-x-4 px-6 py-4 pb-6 md:relative">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'PopupActions',
}
</script>
