import Vue from 'vue'
import DayJS from 'dayjs'

Vue.filter("toDateTime", (value) => {
    if (!value) return "";
    value *= 1000;
    return DayJS(value).format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("toDate", (value) => {
    if (!value) return "";
    value *= 1000;
    return DayJS(value).format("YYYY-MM-DD");
});

// 角色等级
Vue.filter("formatRoleAuthorityLevel", (value) => {
    return value === -1 ? "超级管理" : ["", "全局", "部门管理", "普通"][value];
});

// 部门成员状态
Vue.filter("formatMemberStatus", (value) => {
    return ["", "在职", "离职", "待入职"][value] || "";
  });

// 审核状态
Vue.filter("fileAuditStatus", (value) => {
    return { 0: '未审核', 1: '通过', 2: '拒绝' }[value] || '';
});

// 容量
Vue.filter("fileSize", (value) => {
    return Number(value / 1024).toFixed(2) + 'KB';
});

// 容量
Vue.filter("fileSizeGB", (value) => {
    return Number(value / 1024 / 1024 / 1024).toFixed(2) + 'GB';
});

// 文件类型
Vue.filter('fileType', value => {
    return ['文件夹', '图片', '视频', '文本', '未知类型'][value];
});