import Vue from 'vue'
Vue.prototype.$ELEMENT = { size: 'small' };

import './assets/sass/element-variables.scss'

import { Button, Form, FormItem, Input, Image, Tabs, TabPane, Link, Loading, Dropdown, DropdownMenu, DropdownItem, Message, 
         Autocomplete, Avatar, Table, TableColumn, Tag, Pagination, Dialog, Select, Option, Switch, Row, Col, Upload,
         DatePicker, Tree, Breadcrumb, BreadcrumbItem, Icon, Checkbox, CheckboxGroup, Radio, RadioGroup, Progress, InputNumber, MessageBox,
         Slider, Rate, Cascader, Popover } from 'element-ui';
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Image)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Link)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Autocomplete)
Vue.use(Avatar)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Switch)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Tree)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Icon)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Progress)
Vue.use(InputNumber)
Vue.use(Slider)
Vue.use(Rate)
Vue.use(Cascader)
Vue.use(Popover)

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(Loading.directive);