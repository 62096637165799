const routesUser = [
    {
        name: 'Log',
        path: '/log',
        component: () => import(/* webpackChunkName: "chunks/paltform" */ '../views/Platform'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                name: 'ManageLog',
                path: '/log/log',
                component: () => import(/* webpackChunkName: "chunks/managelog" */ '../views/Log/Log'),
                meta: {
                    requiresAuth: true,
                    title: '操作日志',
                },
            }
        ],
    },
]

export default routesUser
