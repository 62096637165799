import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '../config'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: config.locale,
    silentTranslationWarn: true,
})

export default i18n
