import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate"

import uploadRequest from './modules/uploadRequest'
import fileFunctions from './modules/fileFunctions'
import broadcasting from './modules/broadcasting'
import fileBrowser from './modules/fileBrowser'
import payments from './modules/payments'
import userAuth from './modules/userAuth'
import sharing from './modules/sharing'
import lists from './modules/lists'
import teams from './modules/teams'
import app from './modules/app'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        uploadRequest,
        fileFunctions,
        broadcasting,
        fileBrowser,
        payments,
        userAuth,
        sharing,
        lists,
        teams,
        app,
    },
    plugins: [createPersistedState({
        storage: window.localStorage,
        paths: ['userAuth.account', 'userAuth.password', 'userAuth.loginToken']
    }), createPersistedState({
        storage: window.localStorage,
        paths: ['userAuth.user']
    })]
})


export default store;
export const useStore = () => store