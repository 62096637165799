var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"appear":"","name":"fade"}},[_c('div',{staticClass:"relative w-full overflow-hidden rounded-xl bg-opacity-80 p-4 shadow-lg backdrop-blur-2xl",class:{
                'bg-red-50 dark:bg-2x-dark-foreground': _vm.item.type === 'danger',
                'bg-green-50 dark:bg-2x-dark-foreground': _vm.item.type === 'success',
            }},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-start"},[(_vm.item.type === 'success')?_c('check-icon',{staticClass:"vue-feather dark:text-green-600 text-green-600",attrs:{"size":"22"}}):_vm._e(),(_vm.item.type === 'danger')?_c('x-icon',{staticClass:"vue-feather dark:text-red-600 text-red-600",attrs:{"size":"22"}}):_vm._e(),_c('p',{staticClass:"px-4 font-bold",class:{
                            'text-green-600': _vm.item.type === 'success',
                            'text-red-600': _vm.item.type === 'danger',
                        }},[_vm._v(" "+_vm._s(_vm.item.message)+" ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }