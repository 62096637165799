import axios from 'axios'
import router from '../../router'
import Vue from 'vue'
import Api from '../../api'
import { md5 } from "md5js";

const defaultState = {
    permission: 'master', // master | editor | visitor
    user: undefined,
    account: '',
    password: '',
    loginToken: '',
    roleMenuList: [],
    permissionList: []
}

const actions = {
    login: ({ state, commit, getters, dispatch }, data) => {
        return new Promise((resolve, reject) => {
            let requestData = JSON.parse(JSON.stringify(data));
            if (state.password === '' || requestData.password.length < 32 || state.account !== requestData.username) {
                requestData.password = md5(md5(data.password, 32).toUpperCase(), 32).toUpperCase();
            }

            Api.user.login(requestData).then(resp => {
                resolve(resp);
                commit('SET_AUTHORIZED', true)
                commit('RETRIEVE_USER', { req: requestData, resp })
                dispatch('getUserInfo', {});
            }).catch(err => {
                reject(err);
            });
        });
    },
    loginSmsCode: ({ state, commit, getters, dispatch }, data) => {
        return new Promise((resolve, reject) => {
            Api.user.loginBySmsCode(data).then(resp => {
                commit('SET_AUTHORIZED', true)
                commit('RETRIEVE_USER', { req: data, resp })
                resolve(resp);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getUserInfo: ({ state, commit, getters, dispatch }, data) => {
        return new Promise((resolve, reject) => {
            Api.user.getUserInfo({ id: getters.user.id }).then((response) => {
                commit('RETRIEVE_USER', { req: { isRememberPWD: false }, resp: response })
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            });

            // get menu list
            dispatch('getMenuByRole');

            // get operate list
            Api.role.getOperateListByRole({ id: getters.user.roleIds }).then(response => {
                commit('SET_PERMISSION_LIST',  response);
            });
        })
    },
    getAppData: ({ commit, getters, dispatch }) => {
        return false;
        return new Promise((resolve, reject) => {
            Api.user.getUserInfo().then((response) => {
                resolve(response)

                commit('RETRIEVE_USER', response.data)
                commit('UPDATE_NOTIFICATION_COUNT', response.data.data.relationships.unreadNotifications.data.length)

                // if (! getters.isBroadcasting && getters.config.broadcasting === 'pusher') {
                //     dispatch('runConnection')
                // }
            })
            .catch((error) => {
                reject(error)

                // Redirect if unauthenticated
                if ([401, 403].includes(error.response.status)) {
                    commit('SET_AUTHORIZED', false)
                }
            });
        })
    },
    logOut: ({ commit, getters }) => {

        let popup = setTimeout(() => {
            commit('PROCESSING_POPUP', {
                title: 'Logging Out',
                message: 'Wait a second...',
            })
        }, 300)

        Api.user.logout()
            .catch((error) => {
                if (error.response.status === 500) {
                    Vue.prototype.$isSomethingWrong()
                }
            })
            .finally(() => {
                clearTimeout(popup)

                commit('PROCESSING_POPUP', undefined)
                commit('DESTROY_DATA')
                commit('SET_AUTHORIZED', false)

                // Get redirect location
                let redirectLocation = 'Homepage';
                // Redirect user
                router.push({name: redirectLocation})
            })
    },
    socialiteRedirect: ({ commit }, provider) => {
        axios
            .get(`/api/socialite/${provider}/redirect`)
            .then((response) => {
                if (response.data.data.url) {
                    window.location.href = response.data.data.url
                }
            })
            .catch(() => Vue.prototype.$isSomethingWrong())
    },
    addToFavourites: (context, folder) => {
        let items = folder;
        let fromFile = null;

        // If dont coming single folder get folders to add to favourites from clipboard
        if (typeof items.from !== 'undefined') {
            items = context.getters.clipboard;
            fromFile = items.from;
        }

        // let itemsToFavourites = items.map((item) => {
        //     if (item.data.type === 'folder') {
        //         if (context.getters.user.data.relationships.favourites.find((folder) => folder.id === item.data.id))
        //             return

        //         return item.data.id;
        //     }
        // })

        // Check is favorites already don't include some of pushed folders
        // let favouritesWidget = items.map((item) => {
        //     if (!context.getters.user.data.relationships.favourites.find((folder) => folder.data.id === item.id)) {
        //         return item
        //     }
        // })

        // Add to favourites UI widget
        // context.commit('ADD_TO_FAVOURITES', favouritesWidget)

        Api.netdisk.batchSaveFile({
            ids: Array.isArray(items) ? items.map(item => item.id).join() : items.id,
            isStar: 1
        }).catch(() => {
            Vue.prototype.$isSomethingWrong()
        });    
    },
    removeFromFavourites: ({ commit, getters, dispatch }, folder) => {
        // Remove from storage
        // commit('REMOVE_ITEM_FROM_FAVOURITES', folder)

        let items = folder;
        let fromFile = null;

        // If dont coming single folder get folders to add to favourites from clipboard
        if (typeof items.from !== 'undefined') {
            items = getters.clipboard;
            fromFile = items.from;
        }

        Api.netdisk.batchSaveFile({
            ids: Array.isArray(items) ? items.map(item => item.id).join() : items.id,
            isStar: 0
        }).catch(() => {
            Vue.prototype.$isSomethingWrong()
        })
        .catch(() => Vue.prototype.$isSomethingWrong())
    },
    readAllNotifications: ({ commit }) => {
        axios.post('/api/notifications/read')
            .then(() => {
                commit('UPDATE_NOTIFICATION_COUNT', 0)
            })
    },
    deleteAllNotifications: ({ commit }) => {
        axios.delete('/api/notifications')
            .then(() => {
                commit('FLUSH_NOTIFICATIONS')
            })
            .catch(() => Vue.prototype.$isSomethingWrong())
    },
    getMenuByRole({ getters, commit }) {
        const user = getters.user;
        Api.role.getMenuListByRole({ id: user.roleIds }).then(res => {
            commit('ROLE_MENU', res)
        });
    }
}

const mutations = {
    CHANGE_TWO_FACTOR_AUTHENTICATION_STATE(state, condition) {
        state.user.data.attributes.two_factor_confirmed_at = condition
    },
    RETRIEVE_USER(state, data) {
        if (data.req.isRememberPWD) {
            state.account = data.req.username;
            state.password = data.req.password;
            state.loginToken = data.resp.loginToken;
        }
        state.user = data.resp;
    },
    SET_PERMISSION(state, role) {
        state.permission = role
    },
    SET_PERMISSION_LIST(state, permissionList) {
        state.permissionList = permissionList;
    },
    DESTROY_DATA(state) {
        state.currentFolder = undefined
        state.user = undefined
        state.app = undefined

        state.clipboard = []
    },
    ADD_TO_FAVOURITES(state, folder) {
        folder.forEach((item) => {
            state.user.data.relationships.favourites.push(item)
        })
    },
    UPDATE_FIRST_NAME(state, name) {
        state.user.data.relationships.settings.data.attributes.first_name = name
    },
    UPDATE_LAST_NAME(state, name) {
        state.user.data.relationships.settings.data.attributes.last_name = name
    },
    UPDATE_AVATAR(state, avatar) {
        state.user.data.attributes.avatar = {
            xs: avatar,
            sm: avatar,
            md: avatar,
        }
    },
    REMOVE_ITEM_FROM_FAVOURITES(state, item) {
        state.user.data.relationships.favourites.data = state.user.data.relationships.favourites.filter(
            (folder) => folder.data.id !== item.data.id
        )
    },
    UPDATE_NAME_IN_FAVOURITES(state, data) {
        // state.user.data.relationships.favourites.find((folder) => {
        //     if (folder.id === data.id) {
        //         folder.name = data.name
        //     }
        // })
    },
    FLUSH_NOTIFICATIONS(state) {
        state.user.data.relationships.readNotifications.data = []
        state.user.data.relationships.unreadNotifications.data = []
    },
    CLEAR_NOTIFICATION_ACTION_DATA(state, notificationId) {
        if (state.user.data.relationships.readNotifications.data.length) {
            state.user.data.relationships.readNotifications.data.map(notification => {
                if (notification.data.id === notificationId) {
                    notification.data.attributes.action = undefined
                }
            })
        }

        if (state.user.data.relationships.unreadNotifications.data.length) {
            state.user.data.relationships.unreadNotifications.data.map(notification => {
                if (notification.data.id === notificationId) {
                    notification.data.attributes.action = undefined
                }
            })
        }
    },
    ROLE_MENU(state, data) {
        state.roleMenuList = data;
    }
}

const getters = {
    // userLimitationReason: (state) => state.user && state.user.data.meta.restrictions.reason,
    userLimitationReason: (state) => '',
    permission: (state) => state.permission,
    permissionList: (state) => state.permissionList,
    user: (state) => state.user,
    roleMenuList: (state) => state.roleMenuList
}

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
}
