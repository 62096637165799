var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-6 flex items-center rounded-xl p-5 shadow-card",class:{
			'dark:bg-green-700/30 bg-green-200': _vm.color === 'green',
			'dark:bg-rose-700/30 bg-rose-200': _vm.color === 'rose',
		}},[(_vm.isLoading)?_c('refresh-cw-icon',{staticClass:"vue-feather mr-4 shrink-0 animate-spin",class:{
				'text-green-700 dark:text-green-500': _vm.color === 'green',
				'text-rose-700 dark:text-rose-500': _vm.color === 'rose',
			},attrs:{"size":"18"}}):_vm._e(),(!_vm.isLoading)?_c('alert-octagon-icon',{staticClass:"vue-feather mr-4 shrink-0",class:{
				'text-green-700 dark:text-green-500': _vm.color === 'green',
				'text-rose-700 dark:text-rose-500': _vm.color === 'rose',
			},attrs:{"size":"18"}}):_vm._e(),_c('p',{staticClass:"text-sm",class:{
				'text-green-700 dark:text-green-500': _vm.color === 'green',
				'text-rose-700 dark:text-rose-500': _vm.color === 'rose',
			}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }