import Vue from 'vue'
import axios from 'axios'
import { events } from '../../bus'
import router from '../../router'
import i18n from '../../i18n'
import Api from '../../api'

const defaultState = {
    currentFolder: undefined,
    selectFolder: undefined,
    isMultiSelectMode: false,
    fastPreview: undefined,
    navigation: { 0: [], 1: [] },
    paginate: undefined,
    isLoading: true,
    clipboard: [],
    entries: [],
    uploadFile: []
}

const actions = {
    getFolder: ({ commit, getters }, data) => {
        return new Promise ((resolve, reject) => {
            let {page, id} = data;
            if(page === 1)
                commit('START_LOADING_VIEW')

            delete data.page;
            delete data.id;
            // id, getters.sorting.URI, page
            Api.netdisk.getFolder({
                pageNumber: page,
                parentId: id || undefined,
                storeType: 1,
                ...data,
                sortType: getters.sorting.field,
                sortOrder: getters.sorting.sort,
            }).then((response) => {
                // commit('SET_CURRENT_FOLDER', response.data.meta.root)
                // commit('SET_PAGINATOR', response.data.meta.paginate)
                // commit('STOP_LOADING_VIEW')
                // commit('ADD_NEW_ITEMS', response.data.data)
                if (id && parseFloat(id) !== 0) {
                    Api.netdisk.getFileDetail({
                        id
                    }, {
                        cancelToken: new axios.CancelToken(e => {
                            events.$on('changeMainNavMenu', function() {
                                e();
                            });
                        })
                    }).then(res => {
                        commit('SET_CURRENT_FOLDER', res)
                    });
                } else {
                    commit('SET_CURRENT_FOLDER', 0)
                    commit('SET_SELECT_FOLDER', 0)
                }

                commit('SET_PAGINATOR', response)
                commit('STOP_LOADING_VIEW')
                commit('ADD_NEW_ITEMS', response.records)

                events.$emit('scrollTop')

                resolve(response);
            })
            .catch((error) => {
                // Redirect if unauthenticated
                if ([401, 403].includes(error.response.status)) {
                    commit('SET_AUTHORIZED', false)
                    router.push({ name: 'SignIn' })
                } else {
                    // Show error message
                    events.$emit('alert:open', {
                        title: i18n.t('popup_error.title'),
                        message: i18n.t('popup_error.message'),
                    })
                }

                reject(error);
            })
        })
    },
    getRecentUploads: ({commit, getters}, page) => {
        return new Promise((resolve, reject) => {
            if (page === 1)
                commit('START_LOADING_VIEW')

            axios
                .get(`${getters.api}/browse/latest?page=${page}`)
                .then((response) => {
                    commit('SET_PAGINATOR', response.data.meta.paginate)
                    commit('SET_CURRENT_FOLDER', undefined)
                    commit('STOP_LOADING_VIEW')
                    commit('ADD_NEW_ITEMS', response.data.data)

                    events.$emit('scrollTop')

                    resolve(response)
                })
                .catch(() => Vue.prototype.$isSomethingWrong())
        })
    },
    getMySharedItems: ({ commit, getters }, page) => {
        return new Promise((resolve, reject) => {
            if (page === 1)
                commit('START_LOADING_VIEW')
            
            Api.share.getShareList({})
                .then((response) => {
                    response.records = response.records.map(item => {
                        return {
                            ...item,
                            id: item.id,
                            name: item.title,
                            type: 0
                        };
                    });
                    commit('SET_PAGINATOR', response)
                    commit('SET_CURRENT_FOLDER', undefined)
                    commit('SET_SELECT_FOLDER', undefined)
                    commit('STOP_LOADING_VIEW')
                    commit('ADD_NEW_ITEMS', response.records)

                    events.$emit('scrollTop')

                    resolve(response)
                })
                .catch(() => Vue.prototype.$isSomethingWrong())
        })
    },
    getTrash: ({ commit, getters }, {page, id}) => {
        return new Promise((resolve, reject) => {
            if (page === 1)
                commit('START_LOADING_VIEW')

                Api.netdisk.recycleList({
                    pageNumber: page,
                    parentId: id || 0,
                    storeId: getters.user.fileStoreId,
                }).then((response) => {
                    response.records = response.records.map(item => {
                        return {
                            ...item.fileDetail,
                            createTime: item.createTime,
                            trashId: item.id,
                            remainingTime: item.remainingTime
                        };
                    });
                    commit('SET_PAGINATOR', response)

                    if (id && parseFloat(id) !== 0) {
                        Api.netdisk.getFileDetail({
                            id
                        }).then(res => {
                            commit('SET_CURRENT_FOLDER', res)
                        });
                    } else {
                        commit('SET_CURRENT_FOLDER', 0)
                    }

                    commit('STOP_LOADING_VIEW')
                    commit('ADD_NEW_ITEMS', response.records)

                    events.$emit('scrollTop')

                    resolve(response)
                })
                .catch((error) => {
                    Vue.prototype.$isSomethingWrong()

                    reject(error);
                });
        })
    },
    getFolderTree: ({ commit, getters }) => {
        return new Promise((resolve, reject) => {
            // Get route
            let route = {
                RequestUpload: `/api/file-request/${router.currentRoute.params.token}/navigation`,
                Public: `/api/sharing/navigation/${router.currentRoute.params.token}`,
            }[router.currentRoute.name] || '/api/browse/navigation'

            console.log(router.currentRoute.name);
            Api.netdisk.getFolderTree({ 
                pageNumber: 1,
                // parentId: 0,
                storeType: 0,
             }).then((response) => {
                commit('UPDATE_FOLDER_TREE', { type: 0, records: response })
                resolve(response)
            })
            .catch((error) => {
                reject(error)

                Vue.prototype.$isSomethingWrong()
            });  

            Api.netdisk.getFolderTree({ 
                pageNumber: 1,
                // parentId: 0,
                storeType: 1,
             }).then((response) => {
                commit('UPDATE_FOLDER_TREE', { type: 1, records: response })
                resolve(response)
            })
            .catch((error) => {
                reject(error)

                Vue.prototype.$isSomethingWrong()
            });  
        })
    },
    setUploadFile({ commit }, data) {
        commit('SET_UPLOAD_FILE', data);
    },
    setSelectFolder({ commit }, id) {
        Api.netdisk.getFileDetail({
            id
        }, {
            cancelToken: new axios.CancelToken(e => {
                events.$on('changeSelectFolder', function() {
                    e();
                });
            })
        }).then(res => {
            commit('SET_SELECT_FOLDER', res);
        });
    }
}

const mutations = {
    SET_PAGINATOR(state, payload) {
        state.paginate = payload
    },
    START_LOADING_VIEW(state) {
        state.entries = []
        state.isLoading = true
    },
    STOP_LOADING_VIEW(state) {
        state.isLoading = false
    },
    SET_CURRENT_FOLDER(state, folder) {
        state.currentFolder = folder
    },
    SET_SELECT_FOLDER(state, folder) {
        state.selectFolder = folder;
    },
    UPDATE_FOLDER_TREE(state, tree) {
        state.navigation[tree.type] = tree.records
    },
    FLUSH_SHARED(state, id) {
        state.entries.find((item) => {
            if (item.data.id === id) item.data.relationships.shared = undefined
        })
    },
    CHANGE_ITEM_NAME(state, file) {
        state.entries.find((item) => {
            if (item.id === file.id) {
                item.name = file.name
                // item.data.attributes.color = file.data.attributes.color ? file.data.attributes.color : null
                // item.data.attributes.emoji = file.data.attributes.emoji ? file.data.attributes.emoji : null
            }
        })
    },
    UPDATE_SHARED_ITEM(state, data) {
        state.entries.find((item) => {
            if (item.data.id === data.data.attributes.item_id) {
                item.data.relationships = {
                    ...item.data.relationships,
                    ...{shared: data}
                }
            }
        })
    },
    UPDATE_ITEM(state, data) {
        state.entries.find((item) => {
            if (item.data.id === data.data.id) item.data = data.data
        })
    },
    ADD_NEW_ITEM(state, folder) {
        state.entries.unshift(folder)
    },
    ADD_NEW_ITEMS(state, items) {
        state.entries = state.entries.concat(items)
    },
    REMOVE_ITEM(state, id) {
        state.entries = state.entries.filter((el) => el.id !== id)
    },
    INCREASE_FOLDER_ITEM(state, id) {
        state.entries.map((el) => {
            if (el.id && el.id === id) el.items++
        })
    },
    REMOVE_ITEM_FROM_CLIPBOARD(state, id) {
        state.clipboard = state.clipboard.filter((element) => element.id !== id)
    },
    ADD_ALL_ITEMS_TO_CLIPBOARD(state) {
        state.clipboard = state.entries
    },
    CLIPBOARD_REPLACE(state, item) {
        if (Array.isArray(item)) {
            state.clipboard = [...item]
        } else {
            state.clipboard = [item]
        }
    },
    ADD_ITEM_TO_CLIPBOARD(state, item) {
        let selectedItem = state.entries.find((el) => el.id === item.id)

        if (state.clipboard.includes(selectedItem)) return

        state.clipboard.push(selectedItem ? selectedItem : state.currentFolder)
    },
    CLIPBOARD_CLEAR(state) {
        state.clipboard = []
    },
    ADD_TO_FAST_PREVIEW(state, item) {
        state.fastPreview = item
    },
    FAST_PREVIEW_CLEAR(state) {
        state.fastPreview = undefined
    },
    TOGGLE_MULTISELECT_MODE(state) {
        state.clipboard = []
        state.isMultiSelectMode = !state.isMultiSelectMode
    },
    DISABLE_MULTISELECT_MODE(state) {
        state.clipboard = []
        state.isMultiSelectMode = false
    },
    SET_UPLOAD_FILE(state, data) {
        state.uploadFile = data;
    }
}

const getters = {
    isMultiSelectMode: (state) => state.isMultiSelectMode,
    currentFolder: (state) => state.currentFolder,
    selectFolder: (state) => state.selectFolder,
    fastPreview: (state) => state.fastPreview,
    navigation: (state) => state.navigation,
    clipboard: (state) => state.clipboard,
    isLoading: (state) => state.isLoading,
    paginate: (state) => state.paginate,
    entries: (state) => state.entries,
    uploadFile: (state) => state.uploadFile,
}

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
}
